import { create } from 'zustand';
import { produce } from 'immer'
import { persist } from 'zustand/middleware'
import { findIndex } from 'lodash'

export const useAccessStore = create(
    persist(
        (set, get) => ({
            deviceId: null,
            isAdminAccess: false,
            currentRoom: null, // deprecated
            isSubscribed: false,
            subscribedRoom: null,
            selectedRooms: [],
            lastSelectedRoom: null,
            setSelectedRooms: (room) => set(produce((state) => {
                const isAdminAccess = get().isAdminAccess;
                const isNormalClient = !isAdminAccess;
;
                let selectedRooms = get().selectedRooms;
                let index = findIndex(selectedRooms, { id: room.id });
                if (index === -1) {
                    // Normal user can only select 1 room for order filter and subscription
                    if (isNormalClient && selectedRooms.length >= 1) {
                        return;
                    }
                    state.selectedRooms = [...selectedRooms, room]
                    state.lastSelectedRoom = room;
                } else {
                    state.selectedRooms = selectedRooms.filter(o => o.id !== room.id)
                    state.lastSelectedRoom = room;
                }
            })),
            setAdminAccess: (isAllowAccess) => set(produce((state) => {
                state.isAdminAccess = isAllowAccess
                if (!!state.isAdminAccess) {
                    console.log('ADMIN ACCESS [TABLET VIEWPORT]')
                }
            })),
            setRoom: (selectedRoom) => set(produce((state) => {
                state.currentRoom = selectedRoom
            })),
            setSubscription: (isSubscribe) => set(produce((state) => {
                state.isSubscribed = isSubscribe
            })),
            setDeviceId: (generatedId) => set(produce((state) => {
                state.deviceId = generatedId
            })),
            setSubscribedRoom: (roomId) => set(produce((state) => {
                state.subscribedRoom = roomId
            })),
        }), {
        name: 'access-storage', // name of the item in the storage (must be unique)
    }))
