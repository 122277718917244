import { create } from 'zustand';
import { produce } from 'immer'

export const useDialogStore = create((set, get) => ({
    isOpen: false,
    confirmBtnContent: '',
    dialogContent: '',
    extraDialogContent: '',
    action: null,
    setOpen: (isOpen) => set(produce((state) => {
        state.isOpen = isOpen;
    })),
    setAction: (action) => set(produce((state) => {
        state.action = action;
    })),
    setCartDialogContent: () => set(produce((state) => {
        state.dialogContent = 'dialog.dialogContent.submit'
        state.confirmBtnContent = 'dialog.btnContent.confirm';
        state.extraDialogContent = '';
    })),
    setServiceDialogContent: (serviceName) => set(produce((state) => {
        state.dialogContent = 'dialog.dialogContent.callService'
        state.confirmBtnContent = 'dialog.btnContent.confirm';
        state.extraDialogContent = serviceName;
    })),
    setClearCartDialogContent: () => set(produce((state) => {
        state.dialogContent = 'dialog.dialogContent.clear'
        state.confirmBtnContent = 'dialog.btnContent.confirm';
        state.extraDialogContent = '';
    })),
    setOrderDeliveredDialogContent: () => set(produce((state) => {
        state.dialogContent = 'dialog.dialogContent.setDelivered'
        state.confirmBtnContent = 'dialog.btnContent.setDelivered';
        state.extraDialogContent = '';
    })),
    setRoomOrderRemovalDialogContent: (roomDisplayName) => set(produce((state) => {
        state.dialogContent = 'dialog.dialogContent.clearRoomOrder'
        state.confirmBtnContent = 'dialog.btnContent.confirm';
        state.extraDialogContent = roomDisplayName;
    })),
}))

export const usePwDialogStore = create((set, get) => ({
    isOpen: false,
    action: null,
    setOpen: (isOpen) => set(produce((state) => {
        state.isOpen = isOpen;
    })),
    setAction: (action) => set(produce((state) => {
        state.action = action;
    }))
}))

export const useAdminDialogStore = create((set, get) => ({
    isOpen: false,
    isReportDialogOpen: false,
    action: null,
    setOpen: (isOpen) => set(produce((state) => {
        state.isOpen = isOpen;
    })),
    setAction: (action) => set(produce((state) => {
        state.action = action;
    })),
    setReportDialogOpen: (isOpen) => set(produce((state) => {
        state.isReportDialogOpen = isOpen;
    })),
}))

export const useNotiStore = create((set, get) => ({
    isNotiOpen: false,
    notiContent: '',
    setNotiOpen: (isNotiOpen) => set(produce((state) => {
        state.isNotiOpen = isNotiOpen;
    })),
    setOrderSuccessNoti: () => set(produce((state) => {
        state.notiContent = 'noti.order-success';
    })),
    setAccessSuccessNoti: () => set(produce((state) => {
        state.notiContent = 'noti.access-success';
    })),
    setAdminSettingSuccessNoti: () => set(produce((state) => {
        state.notiContent = 'noti.admin-setting-success';
    })),
    setServiceCallSuccessNoti: () => set(produce((state) => {
        state.notiContent = 'noti.service-success';
    })),
    setClearCartNoti: () => set(produce((state) => {
        state.notiContent = 'noti.cart-cleared';
    })),
    setOrderDeliveredSuccessNoti: () => set(produce((state) => {
        state.notiContent = 'noti.set-delivered';
    })),
    setOrderConfirmedSuccessNoti: () => set(produce((state) => {
        state.notiContent = 'noti.set-confirmed';
    })),
    setRoomOrderRemovalSuccessNoti: () => set(produce((state) => {
        state.notiContent = 'noti.room-order-removal-success';
    })),
}))