import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useDialogStore } from '../stores/dialogStore';
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next';

export default function Confirmation() {
    const { t } = useTranslation(['translation']);
    const setDialogOpen = useDialogStore((state) => state.setOpen)

    const { isOpen: isDialogOpen, dialogContent, extraDialogContent, confirmBtnContent, confirm } = useDialogStore(
        (state) => ({
            isOpen: state.isOpen,
            dialogContent: state.dialogContent,
            extraDialogContent: state.extraDialogContent,
            confirmBtnContent: state.confirmBtnContent,
            confirm: state.action
        }),
        shallow
    )
    return (
        <Dialog open={isDialogOpen} onClose={setDialogOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <DialogTitle as="h3" className="text-xl font-semibold text-gray-900">
                                {t('dialog.confirmation', { ns: 'translation' })}
                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-lg text-gray-500">
                                        {t(dialogContent, { ns: 'translation' })}
                                    </p>
                                    {extraDialogContent && <p className="underline underline-offset-4 mt-2 text-lg text-gray-900 text-semibold">
                                        {t(extraDialogContent, { ns: 'translation' })}
                                    </p>}
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                                type="button"
                                onClick={() => confirm()}
                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-lg font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2"
                            >
                                {t(confirmBtnContent, { ns: 'translation' })}
                            </button>
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => setDialogOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                            >
                                {t('dialog.cancel', { ns: 'translation' })}
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}