export default function Loading() {
    return <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-20 overflow-hidden bg-gray-700 bg-opacity-75 flex flex-col items-center justify-center">
        <svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <h2 className="mt-4 text-center text-white text-xl font-semibold">載入中，請稍候。。。</h2>
        <h2 className="mt-2 text-center text-gray-300 text-lg font-semibold">- Loading -</h2>
    </div>
}
