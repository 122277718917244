import { useState, useEffect } from 'react';
import { useAccessStore } from '../stores/accessStore';

const MOBILE_VIEWPORT_WIDTH_SIZE_LIMIT = 500;

const useDeviceDetection = () => {
    const [device, setDevice] = useState('');
    const setAdminAccess = useAccessStore((state) => state.setAdminAccess)

    useEffect(() => {
        const handleDeviceDetection = () => {
            const userAgent = navigator.userAgent.toLowerCase();
            const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
            const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

            // hit agent and viewport
            if (isMobile && window.innerWidth <= MOBILE_VIEWPORT_WIDTH_SIZE_LIMIT) {
                setAdminAccess(true);
                setDevice('Mobile')
            // hit agent and viewport
            } else if (isTablet && window.innerWidth > MOBILE_VIEWPORT_WIDTH_SIZE_LIMIT) {
                setAdminAccess(false);
                setDevice('Tablet')
            // hit viewport only
            } else if (window.innerWidth <= MOBILE_VIEWPORT_WIDTH_SIZE_LIMIT) {
                setAdminAccess(true);
                setDevice('Mobile')
            // hit viewport only
            } else if ( window.innerWidth > MOBILE_VIEWPORT_WIDTH_SIZE_LIMIT) {
                setAdminAccess(false);
                setDevice('Tablet')
            }
        };

        handleDeviceDetection();
        window.addEventListener('resize', handleDeviceDetection);

        return () => {
            window.removeEventListener('resize', handleDeviceDetection);
        };
    }, []);

    return device;
};

export default useDeviceDetection;