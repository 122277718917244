import { create } from 'zustand';
import { produce } from 'immer'
import { sortBy, findIndex } from 'lodash'

export const useCartStore = create((set, get) => ({
  cartItems: [],
  products: [],
  isCategorySelected: false,
  selectedCat: null,
  showOptionsWithSelectedProductId: null,
  targetProductId: null,
  resetTargetProductId: () => set(produce((state) => {
    state.targetProductId = null;
  })),
  selectCategory: (products, selectedCat) => set(produce((state) => {
    state.products = products.filter(product => {
      return product.categoryId === selectedCat
    })
    state.isCategorySelected = true
    state.selectedCat = selectedCat;
  })),
  backToCategory: () => set(produce((state) => {
    if (!!state.showOptionsWithSelectedProductId) {
      state.showOptionsWithSelectedProductId = null;
      if (state.selectedCat) {
        state.products = state.products.filter(product => {
          return product.categoryId === state.selectedCat
        })
      }
    } else {
      state.products = []
      state.isCategorySelected = false
      state.selectedCat = null
    }
  })),
  showOptionList: (product) => set(produce((state) => {
    state.showOptionsWithSelectedProductId = product.id
  })),
  addCartItem: (cartItem) => set(produce((state) => {
    let _cartItems = get().cartItems;
    let index = findIndex(_cartItems, { id: cartItem.id });

    if (index !== -1) {
      let target = _cartItems[index];

      // store the state
      state.targetProductId = target.id;

      let quantity = target.quantity
      state.cartItems.splice(index, 1, {
        ...target,
        quantity: quantity += 1
      });

      state.cartItems = sortBy(state.cartItems, ({ id }) => id === state.targetProductId ? 0 : 1)
    } else {
      // store the state
      state.targetProductId = cartItem.id;

      state.cartItems = [{
        ...cartItem,
        quantity: 1
      }, ..._cartItems]
    }
  })),
  deductCartItem: (cartItem) => set(produce((state) => {
    let _cartItems = get().cartItems;
    let index = findIndex(_cartItems, { id: cartItem.id });

    if (index !== -1) {
      let target = _cartItems[index];

      // store the state
      state.targetProductId = target.id;

      let quantity = target.quantity

      // remove if one left
      if (target.quantity === 1) {
        const remainingCartItems = _cartItems.filter(o => {
          return o.id !== cartItem.id
        });
        state.cartItems = remainingCartItems;
        return
      }

      state.cartItems.splice(index, 1, {
        ...target,
        quantity: quantity -= 1
      });
    }
  })),
  addCartItemQuantity: (cartItem) => set(produce((state) => {
    let _cartItems = get().cartItems;
    let index = findIndex(_cartItems, { id: cartItem.id });

    if (index !== -1) {
      let target = _cartItems[index];

      // store the state
      state.targetProductId = target.id;

      let quantity = target.quantity
      state.cartItems.splice(index, 1, {
        ...target,
        quantity: quantity += 1
      });
    }
  })),
  clearCart: () => set(produce((state) => {
    state.cartItems = [];
  })),
}))

export const useOrderStore = create((set, get) => ({
  currentOrder: null,
  orders: [],
  setOrders: (orders) => set(produce((state) => {
    state.orders = orders;
  })),
  setCurrentOrder: (order) => set(produce((state) => {
    state.order = order;
  })),
}))