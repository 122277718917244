import { useState, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { useAdminDialogStore } from '../stores/dialogStore';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow'
import axios from 'axios'
import { orderBy, flattenDeep, groupBy, sumBy, head } from 'lodash'

import * as XLSX from 'xlsx';
import { format, startOfToday, endOfToday, startOfYesterday, endOfYesterday } from 'date-fns'
import qs from 'qs'


export default function ReportDialog() {
    const { t } = useTranslation(['translation']);
    const setReportDialogOpen = useAdminDialogStore((state) => state.setReportDialogOpen)

    const { isReportDialogOpen } = useAdminDialogStore(
        (state) => ({
            isReportDialogOpen: state.isReportDialogOpen
        }),
        shallow
    )

    const closeAllDialog = () => {
        setReportDialogOpen(false);
    }

    const generateRoomData = async (start, end, reportName) => {
        try {
            const queryString = qs.stringify({
                sort: ['createdAt:asc'],
                filters: {
                    createdAt: {
                        $gt: start,
                        $lt: end
                    },
                    deliveredAt: {
                        $null: false
                    }
                },
                populate: '*',
            }, {
                encodeValuesOnly: true, // prettify URL
            });
            const orders = await axios.get(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders?${queryString}`);
            if (orders) {
                /* flatten objects */
                const data = orderBy(orders.data.data, ['createdAt'], ['asc'])
                const rows = data.map(row => ({
                    room: row.room.displayName,
                    orderId: row.orderId,
                    orderType: row.orderType,
                    orderItem: row.orderType === 'SERVICE' ? row.cartSnapshot.nameTc : row.cartSnapshot.map(o => {
                        return `${o.nameTc || o.name}${o.optionNameTc ? `, ${o.optionNameTc}` : ''} x ${o.quantity}`
                    }).join(', '),
                    totalItems: row.totalItems,
                    submittedAt: !row.submittedAt ? '' : format(row.submittedAt, 'yyyy-MM-dd HH:mm:ss'),
                    confirmedAt: !row.confirmedAt ? '' : format(row.confirmedAt, 'yyyy-MM-dd HH:mm:ss'),
                    deliveredAt: !row.deliveredAt ? '' : format(row.deliveredAt, 'yyyy-MM-dd HH:mm:ss')
                }));

                /* generate worksheet and workbook */
                const worksheet = XLSX.utils.json_to_sheet(rows);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Room Orders");

                /* fix headers */
                XLSX.utils.sheet_add_aoa(worksheet, [["房間名稱", "訂單號碼", "訂單類型", "訂單內容", "總數", "送出時間", "確認時間", "完成時間"]], { origin: "A1" });
                /* calculate column width */
                worksheet["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 150 }, { wch: 10 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];
                /* create an XLSX file and try to save to Presidents.xlsx */
                XLSX.writeFile(workbook, `${reportName}.xlsx`, { compression: true });
            }

        } catch (error) {
            console.error(error)
            // TODO error handling
        }
    }

    const generateProductData = async (start, end, reportName) => {
        try {
            const queryString = qs.stringify({
                sort: ['createdAt:asc'],
                filters: {
                    createdAt: {
                        $gt: start,
                        $lt: end
                    }
                },
                populate: '*',
            }, {
                encodeValuesOnly: true, // prettify URL
            });
            const orders = await axios.get(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders?${queryString}`);
            if (orders) {
                /* flatten objects */
                const data = orderBy(orders.data.data, ['createdAt'], ['asc'])
                const productsOnly = data.filter(o => o.orderType === 'PRODUCT')
                const allCartSnapshot = flattenDeep(productsOnly.map(o => {
                    let x = o.cartSnapshot.map(c => {
                        return {
                            ...c,
                            orderId: o.orderId
                        }
                    });
                    return x
                }));
                const grouped = groupBy(allCartSnapshot, 'id');
                const result = [];
                for (const groupItem in grouped) {
                    const product = {
                        ...head(grouped[groupItem]),
                        orderIds: grouped[groupItem].map(o => o.orderId).join(', '),
                        totalQuantity: sumBy(grouped[groupItem], 'quantity')
                    }
                    result.push(product)
                }

                const sortedResult = orderBy(result, ['totalQuantity'], ['desc'])
                const rows = sortedResult.map(row => ({
                    product: `${row.nameTc}${row.optionNameTc ? `, ${row.optionNameTc}` : ''}`,
                    orderIds: row.orderIds,
                    totalQuantity: row.totalQuantity,
                }));

                /* generate worksheet and workbook */
                const worksheet = XLSX.utils.json_to_sheet(rows);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

                /* fix headers */
                XLSX.utils.sheet_add_aoa(worksheet, [["產品名稱", "訂單號碼", "總數量"]], { origin: "A1" });
                /* calculate column width */
                worksheet["!cols"] = [{ wch: 40 }, { wch: 150 }, { wch: 20 }];
                /* create an XLSX file and try to save to Presidents.xlsx */
                XLSX.writeFile(workbook, `${reportName}.xlsx`, { compression: true });
            }

        } catch (error) {
            console.error(error)
            // TODO error handling
        }
    }


    return (
        <Dialog open={isReportDialogOpen} onClose={setReportDialogOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full max-w-4xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <DialogTitle as="h3" className="text-2xl mb-2 font-semibold text-gray-900">
                                    所有報表
                                </DialogTitle>

                                <fieldset className="mt-8 flex items-center justify-center">
                                    <div className="w-full flex flex-col">
                                        <legend className="text-lg mb-1 font-semibold text-gray-900">下載銷售報表 (XLSX)</legend>
                                        <span className="text-sm mb-2 text-gray-500">* 只有狀態為<b>已完成</b>的訂單才會顯示於表報內</span>
                                        <div className="mt-8 grid grid-cols-2 w-full gap-x-8 gap-y-8">
                                            <button
                                                type="button"
                                                onClick={() => generateProductData(startOfToday(), endOfToday(), `產品銷售報表(今日)_${format(startOfToday, 'yyyyMMdd')}`)}
                                                className="w-full rounded-md border border-gray-300 bg-white text-xl px-2 py-3 font-medium text-gray-500 shadow-sm"
                                            >
                                                產品銷售 (今日)
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => generateProductData(startOfYesterday(), endOfYesterday(), `產品銷售報表(昨日)_${format(startOfYesterday, 'yyyyMMdd')}`)}
                                                className="w-full rounded-md border border-gray-300 bg-white px-2 py-3 text-xl font-medium text-gray-500 shadow-sm"
                                            >
                                                產品銷售 (昨日)
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => generateRoomData(startOfToday(), endOfToday(), `房間訂單報表(今日)_${format(startOfToday, 'yyyyMMdd')}`)}
                                                className="w-full rounded-md border border-gray-300 bg-white px-2 py-3 text-xl font-medium text-gray-500 shadow-sm"
                                            >
                                                房間訂單 (本日)
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => generateRoomData(startOfYesterday(), endOfYesterday(), `房間訂單報表(昨日)_${format(startOfYesterday, 'yyyyMMdd')}`)}
                                                className="w-full rounded-md border border-gray-300 bg-white px-2 py-3 text-xl font-medium text-gray-500 shadow-sm"
                                            >
                                                房間訂單 (昨日)
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>
                        </div>
                        <div className="mt-10 sm:grid sm:grid-flow-row-dense sm:gap-3">
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => closeAllDialog()}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                            >
                                {t('admin.close', { ns: 'translation' })}
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}