import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { usePwDialogStore, useAdminDialogStore, useNotiStore } from './stores/dialogStore';
import { useAccessStore } from './stores/accessStore';
import { shallow } from 'zustand/shallow'

export default function ReportLanding() {
    const [adminPassword, setAdminPassword] = useState('');
    const navigate = useNavigate()
    const { i18n, t } = useTranslation();
    const setDialogOpen = usePwDialogStore((state) => state.setOpen)
    const setReportDialogOpen = useAdminDialogStore((state) => state.setReportDialogOpen)

    const setAccessSuccessNoti = useNotiStore((state) => state.setAccessSuccessNoti)
    const setNotiOpen = useNotiStore((state) => state.setNotiOpen)
    const onChangeHandler = event => {
        setAdminPassword(event.target.value);
    };

    const verifyPassword = (password) => {
        const isVerified = password === process.env.REACT_APP_ADMIN_PW;
        if (!!isVerified) {
            setAccessSuccessNoti();
            setNotiOpen(true);

            setTimeout(() => {
                setNotiOpen(false);
                setReportDialogOpen(true); // open setting dialog
            }, 1000);
        } else {
            // TODO noti error
        }
    }

    const { isReportDialogOpen } = useAdminDialogStore(
        (state) => ({
            isReportDialogOpen: state.isReportDialogOpen,
        }),
        shallow
    )

    useEffect(() => {
        setAdminPassword('')
    }, [isReportDialogOpen]);

    return (
        <div className="bg-white h-screen flex flex-col justify-center items-center w-screen px-4 sm:px-6 lg:px-8">

            <div className="flex flex-col">
                <img
                    src={'/logo.jpg'}
                    className="w-52 h-44 object-contain object-center"
                />
            </div>
            <div className="max-w-7xl p-20 bg-white rounded-xl border-2 border-red-600">
                <div className="w-full">
                    <div className="mb-2 flex flex-col gap-y-1">
                        <span className="flex justify-center items-center rounded-md text-red-600 text-2xl font-semibold">
                            Report Portal
                        </span>
                    </div>
                </div>
                <div>
                    <div className="mt-4 mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-200">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                        </svg>
                    </div>
                    <div className="mt-8 text-center sm:mt-5">
                        <h3 className="tracking-widest text-2xl mb-4 font-semibold text-gray-900">
                            {t('admin.restricted-access', { ns: 'translation' })}
                        </h3>
                        <div className="mt-8">
                            <div className="relative">
                                <label
                                    htmlFor="name"
                                    className="absolute -top-3 left-2 inline-block bg-white px-1 text-lg font-medium text-gray-600"
                                >
                                    {t('admin.enter-pw', { ns: 'translation' })}
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={adminPassword}
                                    onChange={onChangeHandler}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 pl-4 text-3xl"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <button
                        type="button"
                        onClick={() => verifyPassword(adminPassword)}
                        className="tracking-widest inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-2xl font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2"
                    >
                        {t('admin.confirm', { ns: 'translation' })}
                    </button>
                </div>
            </div>
        </div>
    );
};