import axios from 'axios'

class WebPushService {
    static hasPermission() {
        return Notification.permission === "granted";
    }

    static async requestPermission() {
        return await Notification.requestPermission();
    }

    static async getSubscription() {
        return await navigator.serviceWorker.ready.then(async (registration) => {
            return await registration.pushManager.getSubscription();
        });
    }

    static async subscribe() {
        if (!'serviceWorker' in navigator) {
            console.error('service worker cannot be found');
            return;
        }
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
        });
        return subscription;
    }

    static async unsubscribe() {
        const subscription = await this.getSubscription();
        if (subscription) {
            await subscription.unsubscribe();
        }
        return subscription;
    }

    static async subscribeTo(subscription, deviceId, room, isAdminAccess, isSelected) {
        let sub = JSON.parse(JSON.stringify(subscription));
        let token = sub.keys.p256dh;
        let auth = sub.keys.auth;
        const data = {
            deviceId,
            sentAt: new Date(),
            endpoint: sub.endpoint,
            token,
            auth,
            room: room.id,
            isAdmin: isAdminAccess,
            isSelected
        }
        return await axios.post(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/push-msg/subscribe`, data);
    }
}

export default WebPushService;