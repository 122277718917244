import { useRouteError } from "react-router-dom";

export default function ErrorRoutes() {
    const error = useRouteError();
    return (
        <div className="bg-white h-full w-full">
            <div className="h-full flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
                <img
                    src={'/logo.jpg'}
                    className="w-40 h-32 object-contain object-center"
                />
                <h3 className="text-center">Unauthorized Access. Please contact technical support.</h3>
            </div>
        </div>
    );
}