import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useNotiStore } from './stores/dialogStore';
import { writeStorage } from '@rehooks/local-storage';

export default function DeviceReg() {
    const navigate = useNavigate()
    const setNotiOpen = useNotiStore((state) => state.setNotiOpen)
    const setAdminSettingSuccessNoti = useNotiStore((state) => state.setAdminSettingSuccessNoti)

    const regDevice = () => {
        setAdminSettingSuccessNoti();
        writeStorage('dk', process.env.REACT_APP_DEVICE_KEY)
        setNotiOpen(true);
        setTimeout(() => {
            setNotiOpen(false);
            return navigate('/');
        }, 2000);
    }
    return (
        <div className="bg-white h-screen flex flex-col justify-center items-center w-screen px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <img
                    onClick={() => regDevice()}
                    src={'/logo.jpg'}
                    className="w-40 h-32 object-contain object-center"
                />
            </div>
        </div>
    );
};