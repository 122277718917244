import { useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { usePwDialogStore, useAdminDialogStore, useNotiStore } from '../stores/dialogStore';
import { useAccessStore } from '../stores/accessStore';

import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next';

export default function PwDialog() {
    const [adminPassword, setAdminPassword] = useState('');
    const { t } = useTranslation(['translation']);
    const setDialogOpen = usePwDialogStore((state) => state.setOpen)
    const setAdminDialogOpen = useAdminDialogStore((state) => state.setOpen)

    const setAdminAccess = useAccessStore((state) => state.setAdminAccess)
    const setAccessSuccessNoti = useNotiStore((state) => state.setAccessSuccessNoti)
    const setNotiOpen = useNotiStore((state) => state.setNotiOpen)
    const { isOpen: isDialogOpen } = usePwDialogStore(
        (state) => ({
            isOpen: state.isOpen
        }),
        shallow
    )

    const onChangeHandler = event => {
        setAdminPassword(event.target.value);
    };

    
    useEffect(() => {
        setAdminPassword('')
    }, [isDialogOpen]);

    const verifyPassword = (password) => {
        const isVerified = password === process.env.REACT_APP_ADMIN_PW;
        if (!!isVerified) {
            setAccessSuccessNoti();
            setNotiOpen(true);

            setTimeout(() => {
                setDialogOpen(false); // close pw dialog
                setNotiOpen(false);
                setAdminDialogOpen(true); // open setting dialog
            }, 1000);
        } else {
            // TODO noti error
        }
    }

    return (
        <Dialog open={isDialogOpen} onClose={setDialogOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <DialogTitle as="h3" className="text-2xl mb-4 font-semibold text-gray-900">
                                    {t('admin.restricted-access', { ns: 'translation' })}
                                </DialogTitle>
                                <div className="mt-2">
                                    <div className="relative">
                                        <label
                                            htmlFor="name"
                                            className="absolute -top-3 left-2 inline-block bg-white px-1 text-md font-medium text-gray-600"
                                        >
                                            {t('admin.enter-pw', { ns: 'translation' })}
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={adminPassword}
                                            onChange={onChangeHandler}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 pl-4 text-2xl"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                                type="button"
                                onClick={() => verifyPassword(adminPassword)}
                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-lg font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2"
                            >
                                {t('admin.confirm', { ns: 'translation' })}
                            </button>
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => setDialogOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                            >
                                {t('admin.cancel', { ns: 'translation' })}
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}