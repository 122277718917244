import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { usePwDialogStore, useAdminDialogStore } from './stores/dialogStore';
import { useAccessStore } from './stores/accessStore';
import { shallow } from 'zustand/shallow'

export default function SelectLang() {
    const navigate = useNavigate()
    const { i18n } = useTranslation();
    const [counter, setCounter] = useState(0);
    const [regCounter, setRegCounter] = useState(0);

    const setDialogOpen = usePwDialogStore((state) => state.setOpen)
    const setAdminDialogOpen = useAdminDialogStore((state) => state.setOpen)
    const { selectedRooms, isAdminAccess } = useAccessStore(
        (state) => ({
            selectedRooms: state.selectedRooms,
            isAdminAccess: state.isAdminAccess
        }),
        shallow
    )
    const selectLanguageAndGoToOrder = (selectedLang) => {
        lang(selectedLang)
        if (!!isAdminAccess) {
            navigate('order-list')
            return;
        }
        navigate('/order')
    }

    const lang = (lng) => {
        i18n.changeLanguage(lng);
    };

    const attemptToSwitchAdmin = () => {
        if (!!isAdminAccess) return;

        let newValue = counter;
        setCounter(newValue + 1);
        if (counter === 2) {
            setDialogOpen(true);
            setCounter(0)
        }
    }

    const accessDeviceReg = () => {
        let newValue = regCounter;
        setRegCounter(newValue + 1);
        if (regCounter === 7) {
            setCounter(0)
            navigate('register-device')
        }
    }

    return (
        <div className="bg-white h-screen flex flex-col justify-center items-center w-screen px-4 sm:px-6 lg:px-8">

            <div className="flex flex-col">
                <img
                    onClick={() => attemptToSwitchAdmin()}
                    src={'/logo.jpg'}
                    className="w-40 h-32 object-contain object-center"
                />
                {!isAdminAccess && !selectedRooms.length && <span className="mb-4 text-red-600 font-medium">Room setting required</span>}
            </div>
            <div className="max-w-7xl p-20 bg-red-500 rounded-xl">
                <div className="w-full">
                    <div className="mb-2 flex flex-col gap-y-1">
                        {!isAdminAccess && <span className="flex tracking-widest justify-center items-center rounded-md text-white text-2xl font-semibold">
                            {selectedRooms[0]?.displayName}
                        </span>}
                        {!!isAdminAccess && <span className="flex justify-center items-center rounded-md text-gray-200 text-lg font-semibold">
                            [ADMIN ACCESS]
                        </span>}
                        {!!isAdminAccess && <span className="flex justify-center items-center rounded-md text-gray-200 text-lg font-semibold">
                            [管理員訪問]
                        </span>}
                    </div>
                    {!isAdminAccess && <div className="mb-10 flex flex-col gap-y-1">
                        <span className="flex justify-center items-center rounded-md text-white text-xl font-normal">
                            請選擇語言
                        </span>
                        <span className="flex justify-center items-center rounded-md text-white text-xl font-normal">
                            请选择语言
                        </span>
                        <span className="flex justify-center items-center rounded-md text-white lg:text-lg text-md font-normal">
                            Please select langauge
                        </span>
                    </div>}
                    {!!isAdminAccess && <div className="mt-6 flex flex-col gap-y-6">
                        <button
                            type="button"
                            onClick={() => selectLanguageAndGoToOrder('tc')}
                            disabled={!selectedRooms.length && !isAdminAccess}
                            className={`${!selectedRooms.length && !isAdminAccess ? 'bg-gray-400' : 'bg-gray-800'} flex justify-center items-center rounded-md bg-gray-800 px-4 py-2 text-2xl font-medium text-white shadow-sm`}
                        >
                            訂單列表
                        </button>
                        <button
                            type="button"
                            onClick={() => setAdminDialogOpen(true)}
                            className={`bg-gray-800 flex justify-center items-center rounded-md bg-gray-800 px-4 py-2 text-2xl font-medium text-white shadow-sm`}
                        >
                            選擇房間
                        </button>
                    </div>}
                    {!isAdminAccess && <div className="flex flex-col gap-y-6">
                        <button
                            type="button"
                            onClick={() => selectLanguageAndGoToOrder('tc')}
                            disabled={!selectedRooms.length && !isAdminAccess}
                            className={`${!selectedRooms.length && !isAdminAccess ? 'bg-gray-400' : 'bg-gray-800'} flex justify-center items-center rounded-md bg-gray-800 px-4 py-2 text-2xl font-medium text-white shadow-sm`}
                        >
                            繁體中文
                        </button>
                        <button
                            type="button"
                            onClick={() => selectLanguageAndGoToOrder('sc')}
                            disabled={!selectedRooms.length && !isAdminAccess}
                            className={`${!selectedRooms.length && !isAdminAccess ? 'bg-gray-400' : 'bg-gray-800'} flex justify-center items-center rounded-md bg-gray-800 px-4 py-2 text-2xl font-medium text-white shadow-sm`}
                        >
                            简体中文
                        </button>
                        <button
                            type="button"
                            onClick={() => selectLanguageAndGoToOrder('en')}
                            disabled={!selectedRooms.length && !isAdminAccess}
                            className={`${!selectedRooms.length && !isAdminAccess ? 'bg-gray-400' : 'bg-gray-800'} flex justify-center items-center rounded-md bg-gray-800 px-4 py-2 text-2xl font-medium text-white shadow-sm`}
                        >
                            ENGLISH
                        </button>
                    </div>}
                </div>
            </div>

            <p class="p-8 absolute bottom-0 right-0 text-xs" onClick={() => accessDeviceReg()}></p>
        </div>
    );
};